import Abstract from "./Abstract";
import LineupService from "./Lineups";
import { helper } from "../helper.js";
import axios from "axios";
export const base_url = "/dashboard";
export const url = process.env.VUE_APP_API_BASE_URL + base_url;
export const abstract = new Abstract(url);
export default class Dashboard {
  static get(params) {
    return abstract.get({ ...params });
  }

  static fields() {
    return new Abstract(url + "/fields").get();
  }

  static clientTypes() {
    return LineupService.clientTypes();
  }

  static getLineups(params){      
		if(!params){
			params = {};
		}    
		return axios.get(url+'/lineups',{params: {...params}});
	}


 static download(params) {
    if(!params){
      params = {};
    }
  params = helper.getFormDataold({...params})
    return axios.post(
      url + "/download",
      params,
      {
        responseType: "blob",
      }
    );
  }


}
