<template>
  <div ref="chart" style="width: 100%; height: 100%"></div>
</template>
<script>
export default {
  name: "vertical-bar-chart",
  props: {
    chart: Object,
  },
  mounted() {
    google.charts.load("current", { packages: ["corechart", "geochart"] });
    google.charts.setOnLoadCallback(this.drawChart);
  },
  methods: {
    drawChart() {
      const { data } = this.chart.datasets[0];
      const list = [
        ["Element", "QTTY", { role: "style" }, { role: "annotation" }],
      ];
      for (let i = 0; i < data.length; i++) {
        const label = this.chart.labels[i];
        const value = Number(data[i]);

        list.push([
          label,
          value,
          "#D32F2F",
          `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} t`,
        ]);
      }
      var values = google.visualization.arrayToDataTable(list);
      var options = {
        // width: '100%',
        // height: 450,
        bar: { groupWidth: "60%" },
        legend: { position: "none" },
        hAxis: {
          title: "QTTY IN TONS",
        },
        vAxis: {
          title: "COMM",
        },
        tooltip: {
          trigger: "hover",
        },
      };
      var chart = new google.visualization.BarChart(this.$refs.chart);
      chart.draw(values, options);
    },
  },
};
</script>
