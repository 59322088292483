<template>
  <div class="main">
    <div class="row">
      <div class="col-12">
        <img
        src="/img/imagem-statistics.png"
        class="thumb"
        alt="Imagem Statistics"
        />
      </div>
    </div>

    <div class="row infos pdd-0-15">
      <div class="col-xl-3 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-cargo.png" alt="icone barco" />
          <p>
            <span>
              <i class="fa fa-spinner fa-spin" v-if="!mounted"></i>
              {{ qty_vessels }}
            </span>
            <template v-if="search.periodo_inicio || search.periodo_fim">Vessels in this period</template>
            <template v-else>Vessels in {{year}}</template>
            
          </p>
        </div>
      </div>

      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-pesso.png" alt="icone barco" />
          <p>
            <span style="font-size: 32px">
              <i class="fa fa-spinner fa-spin" v-if="!mounted"></i>
              {{ qty_toneladas ? qty_toneladas + "T" : "" }}
            </span>
            <template v-if="search.periodo_inicio || search.periodo_fim">Quantity in tons</template>
            <template style="padding: 10px 0;" v-else>Quantity in tons in {{year}}</template>
            
          </p>
        </div>
      </div>
      <div class="col-xl-5 col-lg-12">
        <div class="row">
          <div class="col-xl-6 col-lg-12">
            <div class="blc-dados">
              <img src="/img/icon-data.png" alt="icone barco" />
              <p>
                <span>
                  <i class="fa fa-spinner fa-spin" v-if="!mounted"></i>
                  {{ qty_waiting_days }}
                </span>
                Waiting days
              </p>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12">
            <div class="blc-dados">
              <img src="/img/icon-cargo.png" alt="icone barco" />
              <p>
                <span>
                  <i class="fa fa-spinner fa-spin" v-if="!mounted"></i>
                  {{ qty_waiting_vessels }}
                </span>
                Vessels waiting
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fundo pesquisa_dashboard">
      <div class="blc-inf-geral">
        <div class="blc-item">
          <h2>Dashboard</h2>
          <form>
            <div class="row justify-content-between">
              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <multiple-select
                multiple
                label="Brazilian Ports"
                placeholder="Select the ports"
                :options="portos || []"
                optionsValue="name"
                inputClasses="input"
                v-model="search.porto_id"
                />
              </div>

              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <multiple-select
                multiple
                label="Commodities types"
                placeholder="Select the comms"
                :options="cargas || []"
                optionsValue="name"
                inputClasses="input"
                v-model="search.carga_tipo_id"
                />
              </div>
              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <multiple-select
                multiple
                label="Commodities"
                placeholder="Select the commoditie"
                :options="commsFilter || []"
                optionsValue="name"
                inputClasses="input"
                v-model="search.carga_id"
                />
              </div>

              

              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <multiple-select
                multiple
                inputClasses="input"
                label="Charterers"
                placeholder="Select the charterers"
                :options="afretadores || []"
                optionsValue="name"
                v-model="search.afretador_id"
                />
              </div>

              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <multiple-select
                multiple
                inputClasses="input"
                label="Disport - POD"
                placeholder="Select the destination port"
                :options="lineupPortos || []"
                optionsValue="port_name"
                v-model="search.destination_port"
                />
              </div>
      
              <div
              class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12"
              v-if="!$root.isClient"
              >
              <multiple-select
              multiple
              inputClasses="input"
              label="Naabsa vessels"
              placeholder="Select naabsa vessels"
              optionsKey="name"
              :options="clientTypes()"
              v-model="search.client_type"
              />
            </div>

            <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
              <base-input
              type="date"
              alternative=""
              label="Start period"
              input-classes="form-control-alternative"
              v-model.lazy="search.periodo_inicio"
              />
            </div>

            <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
              <base-input
              type="date"
              alternative=""
              label="End period"
              input-classes="form-control-alternative"
              v-model.lazy="search.periodo_fim"
              />
            </div>
            <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
              <multiple-select
              multiple
              inputClasses="input"
              label="Origin - POL"
              placeholder="Select the port of origin"
              :options="lineupPortos || []"
              optionsValue="port_name"
              v-model="search.port_of_load"
              />
            </div>
            <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
              <label style="color: transparent;">block</label>
              <button
              class="submit text-center cursor-pointer w-100 mx-0 "

              :disabled="!mounted"
              value="Search"
              @click="listar()"
              >
              <i class="fa fa-spinner fa-spin" v-if="!mounted" /> Search
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="blc-inf-geral">
    <div class="blc-item">
      <h2>
        Share per Charterers
        <i class="fa fa-spinner fa-spin" v-if="!mounted" />
      </h2>

      <div class="form-row">
        <div class="col-md-3">
          <div
          class="blc-tabela zebrada scroll"
          style="border-bottom: none"
          >
          <table
          class="zebrada zebra2"
          style="min-width: auto"
          v-if="mounted"
          >
          <thead>
            <tr>
              <th class="text-left p-2">Charter</th>
              <th class="text-right p-2">Vessel</th>
            </tr>
          </thead>

          <tbody>
            <tr
            v-for="(afretador, index) in afretadoresChart.chartData
            .labels"
            >
            <td class="text-left">{{ afretador }}</td>
            <td class="text-right px-4">
              {{ getChartDataByIndex(afretadoresChart, index) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-md-3"></div>

  <div class="col-12 col-md-6" style="position: relative">
    <small
    :style="`position: absolute;
    top: 10px;
    right: 115px;
    font-weight: bold;
    z-index: 99;
    text-transform: uppercase;
    ${mounted ? '' : 'display:none'}
    `"
    >Charterers</small
    >
    <google-chart
    v-if="mounted"
    type="PieChart"
    :data="afretadoresChart.chartData"
    :extra-options="{
      width: '100%',
      height: 350,
    }"
    class="chart2"
    :colors="portosChart.chartData.datasets[0].backgroundColor"
    :handle-label="
    (label, data) => {
      return `${label}: ${data} VESSEL${data > 1 ? 'S' : ''}`;
    }
    "
    />
  </div>
</div>
</div>
</div>

<div class="blc-inf-geral">
  <div class="blc-item">
    <h2>
      CARGO SHIPMENT
      <i class="fa fa-spinner fa-spin" v-if="!mounted" />
    </h2>

    <div class="row justify-content-end">
      <div class="col-12 col-md-6" style="position: relative">
        <small
        :style="`position: absolute;
        bottom: 30px;
        left: 90px;
        font-weight: bold;
        z-index: 99;
        text-transform: uppercase;
        ${mounted ? '' : 'display:none'}
        `"
        >Vessels</small
        >
        <google-chart
        v-if="mounted"
        type="GeoChart"
        :data="portosChart.chartData"
        :extra-options="{
          is3D: true,
          colorAxis: { colors: ['#bfe6ff', '#0d2bff'] },
        }"
        />
      </div>
      <div class="col-12 col-md-6" style="position: relative">
        <small
        :style="`position: absolute;
        top: 30px;
        right: 115px;
        font-weight: bold;
        z-index: 99;
        text-transform: uppercase;
        ${mounted ? '' : 'display:none'}
        `"
        >{{search.carga_tipo_id[0] == 2 && search.carga_tipo_id.length == 1 ? 'Destination' : 'Origin'}}</small
        >
        <google-chart
        v-if="mounted"
        type="PieChart"
        :data="portosChart.chartData"
        :extra-options="{ pieHole: 0.6 }"
        :handle-label="
        (label, data) => {
          return `${label}: ${data} VESSEL${data > 1 ? 'S' : ''}`;
        }
        "
        :colors="portosChart.chartData.datasets[0].backgroundColor"
        />
      </div>
    </div>
  </div>
</div>

<div class="blc-inf-geral">
  <div class="blc-item">
    <h2>
      Brazilian Port
      <i class="fa fa-spinner fa-spin" v-if="!mounted" />
    </h2>

    <div class="row justify-content-end">
           <div class="col-12 col-md-6" style="position: relative">
        <small
        :style="`position: absolute;
        top: 30px;
        right: 98px;
        width:130px;
        font-weight: bold;
        z-index: 99;
        
        text-transform: uppercase;
        ${mounted ? '' : 'display:none'}
        `"
        >PORT OF LOADING</small
        >
        <google-chart
        v-if="mounted"
        type="PieChart"
        :data="polChart.chartData"
        :extra-options="{ pieHole: 0.6 }"
        :handle-label="
        (label, data) => {
          return `${label}: ${data} VESSEL${data > 1 ? 'S' : ''}`;
        }
        "
        :colors="polChart.chartData.datasets[0].backgroundColor"
        />
      </div>
      <div class="col-12 col-md-6" style="position: relative">
        <small
        :style="`position: absolute;
        top: 30px;
         right: 98px;
        width:130px;
        font-weight: bold;
        z-index: 99;
        text-transform: uppercase;
        ${mounted ? '' : 'display:none'}
        `"
        >PORT OF DISCHARGE</small
        >
        <google-chart
        v-if="mounted"
        type="PieChart"
        :data="podChart.chartData"
        :extra-options="{ pieHole: 0.6 }"
        :handle-label="
        (label, data) => {
          return `${label}: ${data} VESSEL${data > 1 ? 'S' : ''}`;
        }
        "
        :colors="podChart.chartData.datasets[0].backgroundColor"
        />
      </div>
    </div>
  </div>
</div>

<div class="blc-inf-geral">
  <div class="blc-item">
    <h2>
      Commodities and Quantities
      <i class="fa fa-spinner fa-spin" v-if="!mounted" />
    </h2>

    <div class="form-row">
      <div class="col-12 col-md-6" style="position: relative">
        <small
        :style="`position: absolute;
        top: 30px;
        right: 150px;
        font-weight: bold;
        z-index: 99;
        text-transform: uppercase;
        ${mounted ? '' : 'display:none'}
        `"
        >COMMODITIES</small
        >
        <google-chart
        v-if="mounted"
        type="PieChart"
        :data="cargasChart.chartData"
        :extra-options="{ pieHole: 0.6 }"
        :handle-label="
        (label, data) => {
          return `${label}: ${formatNumber(data)} t`;
        }
        "
        />
      </div>
      <div class="col-12 col-md-6 p-0">
        <vertical-bar-chart
        v-if="mounted && cargasChart.chartData.datasets[0].data.length"
        :chart="cargasChart.chartData"
        class

        />
        <div v-else><p>No data</p></div>
      </div>
    </div>
  </div>
</div>
<div class="blc-inf-geral">
  <div class="blc-item">
    <h2>
      List of vessels
      <i class="fa fa-spinner fa-spin" v-if="load" />
      <button class="float-right submit text-center cursor-pointer mx-0 " style="    position: relative;
    bottom: 9px;" v-if="orderList.length" @click="exportLineups()" >Export</button>
    </h2>

    <div class="form-row">
      <div class="col-12 col-md-12" style="position: relative">
        <div
        class="blc-tabela zebrada scroll mb-0"         
        v-dragscroll
        >
        <table class="zebrada mb-0" vue-dragscroll>
          <thead style="position: initial">
            <tr v-if="orderList.length">
              <th>Port</th>
              <th>Terminal</th>
              <th>Berth</th>
              <th>Vessel</th>                          
              <th style="max-width: 100px">QTTY</th>
              <th>Comm</th>
              <th>ETA</th>
              <th>ETB</th>
              <th>ETC</th>
              <th>Charter</th>              
              <th>POD</th>
              <th>POL</th>

            </tr>
          </thead>

          <tbody class="list">
            <template v-for="(row, index) in orderList">
              <tr>    
                <td>                  
                  {{ row.porto?row.porto.name:'' }}
                </td>
                <td>                  
                  {{ row.terminal?row.terminal.name:'' }}
                </td>
                <td>                  
                  {{ row.berco?row.berco.name:'' }}
                </td>
                <td>                  
                  {{ row.navio.name }}
                </td>
                <td>                  
                  {{ formatQty(row.qty) }}
                </td>
                <td>                  
                  {{ row.carga.name }}
                </td>                
                <td>                  
                  {{ formatDate(row.eta) }}
                </td>
                <td>                  
                  {{ formatDate(row.etb) }}
                </td>
                <td>                  
                  {{ formatDate(row.etc) }}
                </td>
                <td>                  
                  {{ row.afretador?row.afretador.name:'' }}
                </td>
                     <td>      

                  <span v-if="row.carga_tipo_id ==2">{{ row.porto?row.porto.name:'' }}</span>
                  <span v-else>{{ row.destination_port?row.destination_port.pais:'' }}</span>
                </td>
                <td>                  
                        <span v-if="row.carga_tipo_id !=2">{{ row.porto?row.porto.name:'' }}</span>
                  <span v-else>{{ row.destination_port?row.destination_port.pais:'' }}</span>
                </td>

              </tr>
              <!-- cargas extras -->
              <tr
              v-for="(carga_afretador, i) in row.cargas_afretadores"
              >
              <td colspan="4"></td>
              <td>{{ formatQty(carga_afretador.qty) }}</td>
              <td>                  
                {{ carga_afretador.carga ? (row.carga.name == carga_afretador.carga.name ? carga_afretador.carga.name :'' ):'' }}
              </td>    
              <td colspan="3"></td>
              <td>                  
                {{ carga_afretador.afretador ? (row.afretador.name == carga_afretador.afretador.name ? carga_afretador.afretador.name :'' ):'' }}
              </td>
              <td></td>
              <td></td>

            </tr>
            <!-- cargas extras -->
          </template>
          <tr v-if="!orderList.length && !load">
            <td coltemplate="100" style="text-align: center">
              no vessel's to call
            </td>
          </tr>
          <tr v-if="load">
            <td colspan=""  style="text-align: center">
              <i
              class="fa fa-spinner fa-spin"
              v-if="load"
              style="font-size: 20px"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div>
      <base-pagination :pagination="pagination" @change="listarLineups()" align="center"></base-pagination>
    </div>
  </div>

</div>
</div>
</div>
</div>
</div>
</template>
<script>
  import * as chartConfigs from "@/components/Charts/config";
  import LineChart from "@/components/Charts/LineChart";
  import BarChart from "@/components/Charts/BarChart";
  import PieChart from "@/components/Charts/PieChart";
  import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
  import PageVisitsTable from "./Dashboard/PageVisitsTable";
  import GoogleChart from "../components/Dashboard/GoogleChart.vue";
  import VerticalBarChart from "../components/Dashboard/VerticalBarChart.vue";
  import Service from "@/services/Dashboard";
  import "../assets/js/google-chart";

  export default {
    components: {
      LineChart,
      BarChart,
      PieChart,
      PageVisitsTable,
      SocialTrafficTable,
      GoogleChart,
      VerticalBarChart,
    },
    data() {
      return {
        pagination: {
          page: 1,
          last_page: 1,
          per_page: 20,
          total:1
        },
        load:false,
        orderList:[],
        year:2024,
        mounted: false,
        qty_vessels: "",
        qty_toneladas: "",
        qty_waiting_days: "",
        qty_waiting_vessels: "",
        portos: [],
        cargas: [],
        navios: [],
        afretadores: [],
        lineupPortos: [],
        comms:[],
        search: {
          carga_tipo_id: [],
          carga_id:[],
          porto_id: [],
          afretador_id: [],
          destination_port: [],
          port_of_load: [],
          periodo_inicio: null,
          periodo_fim: null,
          navio_id: [],
          client_type: [],
        },
        cargasChart: {
          chartData: {
            datasets: [
            {
              data: [],
              backgroundColor: [
              // "#073763",
              // "#1D4971",
              // "#345B7F",
              // "#4A6D8D",
              // "#607F9B",
              // "#7791A9",
              // "#8DA3B7",
              // "#A3B5C5",
              // "#BAC7D3",
              // "#D0D9E1",
              
              ],
            },
            ],
            labels: [],
          },
          extraOptions: chartConfigs.getPieOptions(70),
        },
        podChart: {
          chartData: {
            datasets: [
            {
              data: [],
              backgroundColor: [

              "#4195cb",
              "#75ca7e",
              "#add674",

              "#e1e54d",
              "#fbb242",
              "#fb9318",
              "#f26533",
              "#8d373a",      
              "#75ca7e",
              "#a1d9fe",
              "#c8e6c9",


              // "#0d2bff",
              // "#1a52ff",
              // "#4070ff",
              // "#5a8aff",
              // "#6b9fff",
              // "#81b5ff",
              // "#95c8ff",
              // "#a1d1ff",
              // "#b3daff",
              // "#bfe6ff",
              // "#c8e6c9",
                // "#073763",
                // "#1D4971",
                // "#345B7F",
                // "#4A6D8D",
                // "#607F9B",
                // "#7791A9",
                // "#8DA3B7",
                // "#A3B5C5",
                // "#BAC7D3",
                // "#D0D9E1",
                ],
              },
              ],
              labels: [],
            },
            extraOptions: chartConfigs.getPieOptions(70),
          },
        polChart: {
          chartData: {
            datasets: [
            {
              data: [],
              backgroundColor: [

              "#4195cb",
              "#75ca7e",
              "#add674",

              "#e1e54d",
              "#fbb242",
              "#fb9318",
              "#f26533",
              "#8d373a",      
              "#75ca7e",
              "#a1d9fe",
              "#c8e6c9",


              // "#0d2bff",
              // "#1a52ff",
              // "#4070ff",
              // "#5a8aff",
              // "#6b9fff",
              // "#81b5ff",
              // "#95c8ff",
              // "#a1d1ff",
              // "#b3daff",
              // "#bfe6ff",
              // "#c8e6c9",
                // "#073763",
                // "#1D4971",
                // "#345B7F",
                // "#4A6D8D",
                // "#607F9B",
                // "#7791A9",
                // "#8DA3B7",
                // "#A3B5C5",
                // "#BAC7D3",
                // "#D0D9E1",
                ],
              },
              ],
              labels: [],
            },
            extraOptions: chartConfigs.getPieOptions(70),
          },
        portosChart: {
          chartData: {
            datasets: [
            {
              data: [],
              backgroundColor: [

              "#4195cb",
              "#75ca7e",
              "#add674",

              "#e1e54d",
              "#fbb242",
              "#fb9318",
              "#f26533",
              "#8d373a",      
              "#75ca7e",
              "#a1d9fe",
              "#c8e6c9",


              // "#0d2bff",
              // "#1a52ff",
              // "#4070ff",
              // "#5a8aff",
              // "#6b9fff",
              // "#81b5ff",
              // "#95c8ff",
              // "#a1d1ff",
              // "#b3daff",
              // "#bfe6ff",
              // "#c8e6c9",
                // "#073763",
                // "#1D4971",
                // "#345B7F",
                // "#4A6D8D",
                // "#607F9B",
                // "#7791A9",
                // "#8DA3B7",
                // "#A3B5C5",
                // "#BAC7D3",
                // "#D0D9E1",
                ],
              },
              ],
              labels: [],
            },
            extraOptions: chartConfigs.getPieOptions(70),
          },
          afretadoresChart: {
            chartData: {
              datasets: [
              {
                data: [],
                backgroundColor: [

                "#4195cb",
                "#75ca7e",
                "#add674",

                "#e1e54d",
                "#fbb242",
                "#fb9318",
                "#f26533",
                "#8d373a",      
                "#75ca7e",
                "#a1d9fe",
                "#c8e6c9",
                ],
              },
              ],
              labels: [],
            },
            extraOptions: chartConfigs.pieChartOptions,
          },
        };
      },
      computed:{
        commsFilter(){
          let commns = this.comms;

          if(this.search.carga_tipo_id.length){
            commns = commns.filter(com => this.search.carga_tipo_id.includes(com.tipo_id))
          }

          return commns;
        }
      },
      methods: {
         exportLineups() {
          this.$notify({
            type: "info",
            message: "Please wait for the download to start",
            horizontalAlign: "center",
          });
      Service.download({ ...this.search }).then(
        (response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          });
          const objectUrl = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = objectUrl;
          a.download = "lineups.xlsx";
          a.click();
          window.URL.revokeObjectURL(objectUrl);
        }
      );
    },
        formatNumber(number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatDate(value) {
          if(!value){
            return ''
          }
          const date = this.$moment(value);
          if (date.year() !== this.$moment().year()) {
            return date.format("DD - MMM YYYY");
          }
          return date.format("DD - MMM");
        },
        getRandomColor() {
          const color = Math.floor(Math.random() * 16777215).toString(16);
          return "#" + color;
        },
        formatQty(qty = 0) {
          qty = typeof qty == "number" ? qty : Number(qty);
          return qty.toLocaleString("en-US", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          });
        },
        async listar() {
          this.pagination.page = 1;
          this.mounted = false;
          this.qty_vessels = "";
          this.qty_toneladas = "";
          this.qty_waiting_days = "";
          this.qty_waiting_vessels = "";
          const { data } = await Service.get({...this.search});
          this.qty_waiting_days = data.qty_waiting_days;
          this.qty_toneladas = data.qty_toneladas;
          this.qty_vessels = data.qty_vessels;
          this.qty_waiting_vessels = data.qty_waiting_vessels;
          this.orderList = data.orderList.data;
          this.pagination.last_page = data.orderList.last_page;
          this.pagination.total = data.orderList.total;
          this.setChart(
            data.afretadores,
            "afretadoresChart",
            "lineups_count",
            "name"
            );
          this.setChart(data.cargas, "cargasChart", "lineups_sum_qty");
          this.setChart(data.portos, "portosChart", "lineups_count", "pais");
          this.setChart(data.pod, "podChart", "lineups_count", );
          this.setChart(data.pol, "polChart", "lineups_count", );
          this.mounted = true;
        },
        async listarLineups() {
          this.load = true;
          
          const { data } = await Service.getLineups({...this.search,page:this.pagination.page});

          this.orderList = data.orderList.data;
          this.pagination.last_page = data.orderList.last_page;
          this.pagination.total = data.orderList.total;

          this.load = false;
        },
        setChart(values, chart, key, name = "name") {
          this[chart].chartData.labels = [];
          this[chart].chartData.datasets[0].data = [];
          for (let i = 0; i < values.length; i++) {
            this[chart].chartData.labels[i] = values[i][name];
            this[chart].chartData.datasets[0].data[i] = values[i][key];
          }
        },
        getChartDataByIndex(chart, index) {
          return chart.chartData.datasets[0].data[index];
        },
        getChartBackGroundColorByIndex(chart, index) {
          return chart.chartData.datasets[0].backgroundColor[index];
        },
        async setFields() {
          const { cargas, portos, lineupPortos, afretadores, navios,comms } = (
            await Service.fields()
            ).data;
          this.cargas = cargas;
          this.portos = portos;
          this.lineupPortos = lineupPortos;
          this.afretadores = afretadores;
          this.navios = navios;
          this.comms = comms;
          this.listar();
        },
        clientTypes() {
          return Service.clientTypes();
        },
      },
      mounted() {

        this.year = new Date().getFullYear();
        // this.search.periodo_inicio = '2023-01-01'
        this.setFields();
      },
    };
  </script>
  <style>
  #filters-row label {
    color: white !important;
  }
  .ball {
    border-radius: 50%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
  .table td {
    padding: 5px 2px;
  }
  .chart {
    position: relative;
    height: 350px;
  }
  .chart2 {
    position: relative;
    height: 300px;
  }

  .zebra2 td {
    padding: 5px 10px;
    font-family: var(--font-montserrat);
    font-weight: var(--font-light);
    color: #242629;
    font-size: 15px;
    text-transform: uppercase;
    border-bottom: solid 1px #282c363d;
  }

  #conteudo .main .blc-dados img{
    background: #59b528;
  }
</style>
